var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetInsightCountStyled', [_c('LabelAtom', {
    attrs: {
      "label": "".concat(_vm.$tc('maintenance.insights'))
    }
  }), _c('ValueStyled', [_vm._v(" " + _vm._s(_vm.value) + " ")]), _c('LabelAtom', {
    staticClass: "hour",
    attrs: {
      "label": "".concat(_vm.$tc('timeScopes.lastDay'))
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }