<template>
  <AssetInsightCountStyled>
    <LabelAtom :label="`${$tc('maintenance.insights')}`" />
    <ValueStyled> {{ value }} </ValueStyled>
    <LabelAtom :label="`${$tc('timeScopes.lastDay')}`" class="hour" />
  </AssetInsightCountStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'

const AssetInsightCountStyled = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  border-radius: 8px;
  height: 40px;
  width: max-content;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  > .hour {
    font-weight: 400;
    font-size: 12px;
  }
`
const ValueStyled = styled('div')`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
`

export default {
  components: {
    AssetInsightCountStyled,
    LabelAtom,
    ValueStyled,
  },
  props: {
    value: {
      type: Number,
    },
  },
  computed: {},
}
</script>
