<template>
  <AssetSeverityStyled>
    <AssetSeverityNonGreenStyled>
      <LabelAtom :label="`${$tc('severity')}`" />
      <SeverityLampsMolecule :severities="severities" :activeIssues="severities" />
    </AssetSeverityNonGreenStyled>
    <AssetSeverityGreenStyled>
      <SeverityLampAtom :class="{ green: severities.includes('Green') }">
        <template v-slot:icon>
          <CheckIcon />
        </template>
      </SeverityLampAtom>
    </AssetSeverityGreenStyled>
  </AssetSeverityStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SeverityLampsMolecule from '@/components/Atomic/Molecules/SeverityLampsMolecule'
import SeverityLampAtom from '@/components/Atomic/Atoms/SeverityLampAtom'
import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import { CheckIcon } from 'vue-feather-icons'

const AssetSeverityStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 5px;
`
const AssetSeverityNonGreenStyled = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  border-radius: 8px 0px 0px 8px;
  height: 40px;
  width: fit-content;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`
const AssetSeverityGreenStyled = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  border-radius: 0px 8px 8px 0px;
  height: 40px;
  width: fit-content;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  .feather-check {
    margin-top: 1px;
    width: 14px;
    height: 14px;
    color: ${({ theme }) => theme.colors.atomic.iconBG};
  }
  .green {
    .feather-check {
      color: ${({ theme }) => theme.colors.atomic.white};
    }
  }
`

export default {
  components: {
    AssetSeverityStyled,
    AssetSeverityNonGreenStyled,
    AssetSeverityGreenStyled,
    SeverityLampsMolecule,
    SeverityLampAtom,
    LabelAtom,
    CheckIcon,
  },
  props: {
    severities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
}
</script>
