var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetSeverityStyled', [_c('AssetSeverityNonGreenStyled', [_c('LabelAtom', {
    attrs: {
      "label": "".concat(_vm.$tc('severity'))
    }
  }), _c('SeverityLampsMolecule', {
    attrs: {
      "severities": _vm.severities,
      "activeIssues": _vm.severities
    }
  })], 1), _c('AssetSeverityGreenStyled', [_c('SeverityLampAtom', {
    class: {
      green: _vm.severities.includes('Green')
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('CheckIcon')];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }